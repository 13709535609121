*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-size: $base-font-size;
}

body {
  position: relative;
  height: 100%;
  margin: 0;
  color: $gray-900;
  font-family: $font-family-base;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

iframe {
  border: 0;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    text-decoration: none;
  }
}

.first-letter-capitalize {
  &:first-letter {
    text-transform: uppercase;
  }
}

span.first-letter-capitalize {
  display: inline-block;
}

hr {
  background: $gray-200;
  height: 1px;
  border: none;
  margin: 0 0 20px 0;
}

.type {
  &-owner {
    @include role-type(#18c8ef);
  }

  &-admin {
    @include role-type(#ff5e5b);
  }

  &-manager {
    @include role-type(#6319b3);
  }

  &-cateringService {
    @include role-type(#52b763);
  }

  &-houseKeeping {
    @include role-type(#2e39dc);
  }

  &-service,
  &-marketing_specialist {
    @include role-type(#2fd6c2);
  }

  &-user,
  &-employee_service,
  &-customerService {
    @include role-type(#707078);
  }

  &-spa {
    @include role-type(#96cb08);
  }

  &-front-desk,
  &-concierge,
  &-reception {
    @include role-type(#fe9000);
  }

  &-maintenance {
    @include role-type($gray-900);
  }

  &-unassigned {
    @include role-type($gray-900);

    &:before {
      border: 1px solid $gray-500 !important;
      background-color: transparent !important;
    }
  }

  &-PENDING span,
  &-AUTO_SENT span {
    color: $blue-500;
  }

  &-CANCELLED span {
    color: $red-500;
  }

  &-PAID span {
    color: $green-500;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-danger,
a.text-danger:hover {
  color: $red-500 !important;
}

.text-success,
a.text-success:hover {
  color: $green-500 !important;
}

.mobile-lock-background {
  background-color: $gray-100;
}

ui-button-circle {
  position: relative;

  &.translated {
    &::after {
      content: '';
      position: absolute;
      right: 5px;
      bottom: 6px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $green-500;
    }
  }
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 9999999 !important;
}
