@import 'variables';
@import 'ngx-toastr/toastr';

.toast-bottom-left {
  bottom: 40px;
  left: 40px;
}

.toast-container .ngx-toastr {
  display: flex;
  align-items: center;
  min-width: 320px;
  min-height: 48px;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  width: auto;
  margin: 20px 0 0 0;
  box-shadow: none;
  cursor: default !important;

  .toast-message {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
    margin: 0 16px 0 0;
  }

  .toast-title {
    display: none;
  }

  .toast-close-button {
    -webkit-mask-image: url('/assets/icons/icons-close.svg');
    mask-image: url('/assets/icons/icons-close.svg');
    mask-size: 12px 12px;
    opacity: 1 !important;
    width: 12px;
    height: 12px;

    background: #ffffff;
    top: auto;
    right: auto;
    text-shadow: none;

    order: 2;
    margin-left: auto;

    flex-shrink: 0;

    span {
      display: none;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.toast-info {
    background: $gray-600;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    background-color: $gray-900;
    color: #ffffff !important;
  }

  &.toast-ai {
    &::before {
      content: '';
      -webkit-mask-image: url('/assets/icons/icons-ai-filled.svg');
      mask-image: url('/assets/icons/icons-ai-filled.svg');
      mask-size: 20px 20px;
      width: 20px;
      height: 20px;
      background: #ffffff;
      margin-right: 16px;
      display: inline-flex;
    }
  }

  &.toast-error {
    background: $red-500;
    color: #ffffff !important;
  }

  &.toast-success {
    background: $green-400;
    color: $gray-900 !important;

    .toast-close-button {
      background: $gray-900;
    }
  }

  &.toast-warning {
    background: $yellow-400;
    color: $gray-900 !important;

    .toast-close-button {
      background: $gray-900;
    }
  }

  &.toast-default {
    background: $blue-300;
    color: $gray-900 !important;

    .toast-close-button {
      background: $gray-900;
    }
  }

  &:hover {
    box-shadow: none;
  }
}

@media only screen and (max-width: #{$mobile-breakpoint}) {
  .toast-bottom-left {
    bottom: 8px;
    left: 8px;
    right: 8px;
    width: auto;
  }
}
