quill-editor {
  display: flex !important;
  flex-direction: column-reverse;

  *:focus {
    outline: none;
  }

  .ql-tooltip {
    z-index: 99;
  }

  .ql-toolbar.ql-snow {
    height: 40px;
    border-radius: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: $gray-100;
    padding: 6px 8px;
    border: none;

    .ql-formats {
      position: relative;
      margin-right: 9px;

      &::after {
        content: '';
        width: 1px;
        height: 16px;
        margin: 6px 0 6px 4px;
        background-color: $gray-350;
        display: inline-flex;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      button,
      span,
      select {
        margin-right: 4px;
      }

      button {
        height: 28px;
        width: 28px;
        border-radius: 6px;
        background: transparent;
        transition: all 0.3s ease;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 16px;

        &.ql-bold {
          background-image: url('/assets/icons/icons-typo-b.svg');
        }

        &.ql-italic {
          background-image: url('/assets/icons/icons-typo-i.svg');
        }

        &.ql-underline {
          background-image: url('/assets/icons/icons-typo-u.svg');
        }

        &.ql-link {
          &::after {
            content: '';
            width: 16px;
            height: 16px;
            -webkit-mask-image: url('/assets/icons/icons-link.svg');
            mask-image: url('/assets/icons/icons-link.svg');
            mask-size: 16px 16px;
            mask-repeat: no-repeat;
            background-color: $gray-900;
          }
        }

        &.ql-mention,
        &.ql-mentions {
          &::after {
            content: '';
            width: 16px;
            height: 16px;
            -webkit-mask-image: url('/assets/icons/icons-hash.svg');
            mask-image: url('/assets/icons/icons-hash.svg');
            mask-size: 16px 16px;
            mask-repeat: no-repeat;
            background-color: $gray-900;
          }
        }

        &.ql-header {
          &::after {
            content: '';
            width: 16px;
            height: 16px;
            -webkit-mask-image: url('/assets/icons/icons-typo-h-3.svg');
            mask-image: url('/assets/icons/icons-typo-h-3.svg');
            mask-size: 16px 16px;
            mask-repeat: no-repeat;
            background-color: $gray-900;
          }
        }

        &.ql-library,
        &.ql-image {
          &::after {
            content: '';
            width: 16px;
            height: 16px;
            -webkit-mask-image: url('/assets/icons/icons-image.svg');
            mask-image: url('/assets/icons/icons-image.svg');
            mask-size: 16px 16px;
            mask-repeat: no-repeat;
            background-color: $gray-900;
          }
        }

        &:hover,
        &.ql-active {
          background-color: $gray-250;
        }

        svg {
          display: none;
        }
      }

      .ql-color {
        height: 28px;
        width: 28px;
        border-radius: 6px !important;
        transition: all 0.3s ease;
        overflow: hidden;

        &:hover,
        &.ql-expanded {
          background-color: $gray-250;
        }

        .ql-picker-label {
          height: 28px;
          width: 28px;
          background: transparent;
          background-image: url('/assets/icons/icons-typo-colors.svg');
          margin-right: 4px;
          border: none !important;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 16px;

          svg {
            display: none;
          }
        }

        .ql-picker-options {
          display: flex !important;
          flex-shrink: 0;
          opacity: 0;
          visibility: hidden;
          padding: 8px;
          border-radius: 8px;
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
          border: solid 1px rgba(0, 0, 0, 0.04);
          background-color: #ffffff;
          width: auto;
          margin-left: -89px;
          left: 50%;
          top: 100%;
          margin-top: -4px;
          transition: all 0.3s ease;
          transform: scale(0.99);

          .ql-picker-item {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            margin: 0 8px 0 0;
            border: none;
            transition: all 0.3s ease;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              box-shadow: 0 0 0 4px $gray-250;
            }
          }
        }

        &.ql-expanded {
          overflow: visible;

          .ql-picker-options {
            opacity: 1;
            visibility: visible;
            margin-top: -4px;
            transform: scale(1);
          }
        }
      }
    }
  }

  .ql-container.ql-snow {
    border: none;

    .ql-editor {
      height: 100%;
      padding: 12px 16px;
      background: $gray-200;
      font-size: 16px;
      font-weight: 400;
      border-radius: 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      font-family: 'Manrope', 'Helvetica', Poppins, sans-serif;
      line-height: 22px;
      color: $gray-900;
      transition: background-color 0.3s ease;
      min-height: 48px;
      max-height: 156px;

      * {
        font-family: 'Manrope', 'Helvetica', Poppins, sans-serif;
      }

      a {
        color: var(--primary, $blue-500);
      }

      span.mention {
        height: 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.3px;
        padding: 1px 4px;
        border-radius: 4px;
        background-color: $royalblue-100;
        color: $royalblue-500;
      }

      img {
        margin: 28px 0 0 0;
      }

      h3 {
        font-size: 18px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 26px;
        letter-spacing: -0.3px;
        color: $gray-900;
        margin: 28px 0 8px 0;

        &:first-child {
          margin-top: 0;
        }
      }

      &:focus {
        background: $gray-250;
      }

      &.ql-blank {
        &::before {
          content: none;
        }

        &::after {
          font-family: 'Manrope', sans-serif;
          content: attr(data-placeholder);
          pointer-events: none;
          display: block;
          margin-top: -1.38rem;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.2px;
          color: $gray-500;
        }
      }
    }

    .ql-mention-list-container {
      border-radius: 8px;
      background: #ffffff;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px rgba(0, 0, 0, 0.1);
      padding: 4px;
      max-height: 190px;
      overflow: auto;
      margin-left: 16px; // for hash in apartment editor - entry instruction
      z-index: 999;

      .ql-mention-list {
        margin: 0;
        padding: 0;

        li {
          border-radius: 6px;
          background: #ffffff;
          padding: 8px 12px;
          font-family: 'Manrope', sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 25px;
          letter-spacing: -0.2px;
          cursor: pointer;
          color: $gray-900;
          display: flex;
          align-items: center;
          transition: background 0.25s ease, color 0.25s ease;
          height: 40px;

          &::before {
            content: '#';
          }

          &:hover,
          &.selected {
            background: $gray-200 !important;
          }
        }
      }
    }
  }

  &.ng-dirty.ng-invalid,
  &.ng-touched.ng-invalid,
  &.input-error {
    .ql-container.ql-snow .ql-editor {
      border: 2px solid $red-400;
      padding: 12px 16px;
      background-color: $red-50;
    }
  }

  &.hide-toolbar {
    .ql-toolbar {
      display: none !important;
    }

    .ql-container.ql-snow .ql-editor {
      border-radius: 12px;
    }
  }

  &.readonly {
    .ql-toolbar {
      display: none !important;
    }

    .ql-editor > * {
      cursor: default;
    }

    .ql-container.ql-snow .ql-editor {
      border-radius: 12px;
      background: #ffffff;
      border: 2px solid $gray-250;
    }
  }
}
