@import 'themes/theme';

.modal-backdrop {
  background-color: $gray-900;
  z-index: 100000;

  &.show {
    opacity: 0.2;
  }
}

.modal {
  z-index: 100001;

  &.fade .modal-dialog {
    transform: translate(0, -30px);
    transition: transform 0.25s ease;
  }

  &.show .modal-dialog {
    transform: translate(0, 0) !important;
  }
}

.modal-dialog-full {
  max-width: none !important;
}

.modal-dialog {
  max-width: 620px;
  width: 100% !important;
  min-height: var(--app-height);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 15px;
  margin: 0 auto;

  &-small {
    max-width: 370px;
  }

  &-medium {
    max-width: 527px;

    .modal-content {
      padding: 24px 29px;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .modal-guest {
    max-width: 550px;
  }
}

.modal-content {
  border: none;
  background: #ffffff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 24px;

  h3 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.2px;
    margin-bottom: 23px;
  }

  section {
    margin-bottom: 16px;

    h3 {
      margin-bottom: 13px;
    }
  }

  &:disabled {
    opacity: 0.4;
    color: #ffffff;
    cursor: default;
    transform: none;
  }

  .btn-block {
    margin-bottom: 12px;
  }
}

.modal-file-library {
  max-width: 1250px;
  padding: 0 20px;

  .modal-content {
    padding: 0;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  }

  .modal-header {
    background: $gray-200;
    height: 61px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 16px 15px;

    .btn-close {
      margin: 0;
    }
  }

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    background-color: $gray-200;
    padding: 13px 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.modal-cancel {
  max-width: 100%;

  .modal-content {
    max-width: 380px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 20px 10px 20px;

    img {
      margin: 30px 0 2px 38px;
    }

    h2 {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.27;
      color: $gray-900;
      margin-bottom: 8px;
    }

    p {
      font-size: 15px;
      line-height: 1.47;
      color: $gray-700;
      margin-bottom: 30px;
    }
  }
}

.modal-welcome {
  max-width: 532px !important;
  padding: 0 !important;

  .modal-content {
    padding: 36px !important;
    border-radius: 20px !important;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05) !important;
  }
}

.modal-dialog.modal-default {
  max-width: 368px;
  padding: 0;

  &.modal-automation-settings {
    max-width: 620px;
    padding: 40px 0;
  }
}

.modal-dialog-slideout {
  .modal-content {
    max-height: calc(100% - 60px);
    overflow-y: auto;
    padding: 24px !important;
  }

  @media only screen and (min-width: 750px) {
    width: 480px;

    .modal-content {
      position: absolute;
      border-radius: 12px;
    }
  }
}

.width-500 {
  max-width: 500px;
}

.modal-dialog-default {
  .modal-content {
    padding: 24px 20px 20px 20px;
    border-radius: 16px;
    max-width: 400px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  }
}

.modal-dialog-medium {
  .modal-content {
    padding: 24px;
    border-radius: 16px;
    max-width: 480px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  }
}

@media only screen and (max-width: #{$sidebar-collapse-breakpoint}) {
  .modal {
    &.fade .modal-dialog {
      transform: translate(0, var(--app-height));
    }

    .modal-default {
      max-width: 768px !important;
    }

    .modal-dialog {
      padding: 0;
      justify-content: flex-end;

      .modal-content {
        padding: 20px;
        border-radius: 16px 16px 0 0;
        box-shadow: none;
      }
    }
  }
}
